/**
 * Схема для логов импорта шаблонов
 * @type {Object}
 * @property {Array<String>} columns - конфиг видимых элементов панели управления
 */
const SchemeCloudImportLog = {
    columns: [
        {
            prop: 'row_num',
            label: '№ строки',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '80px'}
        },
        {
            prop: 'gid',
            label: 'GID',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '120px'}
        },
        {
            prop: 'address',
            label: 'Адрес',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '450px'}
        },
        {
            prop: 'side',
            label: 'Сторона',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '80px'}
        },
        {
            prop: 'lat',
            label: 'Широта',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '150px'}
        },
        {
            prop: 'lng',
            label: 'Долгота',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '150px'}
        },
        {
            prop: 'report',
            label: '',
            isShow: true,
            isSlot: true,
            columnStyles: {width: '450px'}
        },
        {
            prop: 'type',
            label: 'Тип поверхности',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'kind',
            label: 'Вид поверхности',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'format',
            label: 'Формат поверхности',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'supplier',
            label: 'Оператор',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'owner',
            label: 'Владелец',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'tax',
            label: 'Налог',
            isShow: true,
            isSlot: true,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'light',
            label: 'Освещение',
            isShow: true,
            isSlot: true,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'material',
            label: 'Материал',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'move_direction',
            label: 'Направление движения',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'links',
            label: 'Ссылки',
            isShow: true,
            isSlot: true,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'print_price',
            label: 'Печать за единицу',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'install_price',
            label: 'Монтаж за единицу',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'additional_install_price',
            label: 'Доп. монтаж',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'city',
            label: 'Город',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'video_time',
            label: 'Хронометраж ролика',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'video_frequency',
            label: 'Частота выходов',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'video_block',
            label: 'Блок, сек.',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'video_block_clients',
            label: 'Клиентов в блоке',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'operating_mode',
            label: 'Режим работы',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'operator_comment',
            label: 'Комментарий оператора',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'technical_requirements',
            label: 'Тех требования',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        },
        {
            prop: 'mount_status',
            label: 'Статус установки',
            isShow: true,
            isSlot: false,
            columnStyles: {width: '100px'}
        }
    ]
}

export default SchemeCloudImportLog;
